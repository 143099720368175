<template>
  <v-container>
    <h1>Base components</h1>
    <section class="mt-4">
      <h3 class="mb-4">Typography</h3>
      <h1>h1 / .text-h1: Lorem ipsum dolor sit amet</h1>
      <h2>h2 /.text-h2: Lorem ipsum dolor sit amet</h2>
      <h3>h3 / .text-h3: Lorem ipsum dolor sit amet</h3>
      <span class="body-2">Formtext / .body-2: Lorem ipsum dolor sit amet</span>
      <p>Default / p / .body-1: Lorem ipsum dolor sit amet</p>
      <div class="caption">Small / .caption: Lorem ipsum dolor sit amet</div>
      <div class="subtitle-1">Overhead / .subtitle-1: Lorem ipsum dolor sit amet</div>
    </section>
    <v-row class="mt-8">
      <v-col cols="12">
        <h3>Colors</h3>
      </v-col>
      <v-col
        v-for="(hex, name) in $vuetify.theme.themes.light"
        :key="name"
        cols="2"
        class="ma-2"
        :style="{
          'background-color': hex,
          color: name === 'white' ? 'var(--v-black-base)' : 'var(--v-white-base)'
        }"
      >
        {{ name }} /
        <br />
        {{ hex }}
      </v-col>
    </v-row>
    <v-row class="mt-8">
      <v-col cols="12">
        <h3>Buttons</h3>
      </v-col>
      <v-col>
        <app-button label="Pimary Button" />
      </v-col>
      <v-col>
        <app-button label="Secondary Button" color="secondary" @click="test" />
      </v-col>
      <v-col>
        <app-button label="Outlined Button" outlined />
      </v-col>
      <v-col>
        <app-button label="Text Button" text />
      </v-col>
      <v-col>
        <app-button label="Icon Button" icon="mdi-plus-circle" outlined />
      </v-col>
      <v-col>
        <app-button label="Custom Icon Button" icon="$iconSave" outlined />
      </v-col>

      <v-col>
        <app-button icon="mdi-arrow-left" small outlined />
      </v-col>
      <v-col>
        <app-button icon="mdi-arrow-right" small outlined />
      </v-col>
    </v-row>
    <v-row class="mt-8">
      <v-col cols="12">
        <h3>Inputs</h3>
      </v-col>
      <v-col cols="3">
        <app-filter v-model="selectedItems" :items="selectItems" label="Please select" />
        selectedItems: {{ selectedItems }}
      </v-col>
      <v-col>
        <app-checkbox v-model="checked" label="Checkbox" value="x" />
        <div>checked: {{ checked }}</div>
      </v-col>
      <v-col>
        <app-checkbox v-model="checked" disabled value="x">Checkbox</app-checkbox>
      </v-col>
      <v-col>
        <app-radio v-model="selectedRadio" :items="radioItems" />
        selectedRadio: {{ selectedRadio }}
      </v-col>
      <v-col>
        <app-radio v-model="selectedRadio" disabled :items="radioItems" />
      </v-col>

      <v-col>
        <app-text-field
          id="test"
          v-model="textInput"
          placeholder="Validated Field"
          rules="required"
          headline="Label"
          name="Feld"
        />
        Textinput: {{ textInput }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h3>Dialog</h3>
      </v-col>
      <v-col>
        <app-dialog v-model="showDialog" query-param="test">
          <template v-slot:activator="{ on }">
            <app-button v-on="on">Show</app-button>
          </template>
          <template v-slot:title>
            <span class="subtitle-1 font-weight-bold">Subtitle</span>
            <h2>Title</h2>
          </template>

          {{
            'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.'.repeat(
              30
            )
          }}
        </app-dialog>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h3>RangeSlider</h3>
      </v-col>
      <v-col>
        <range-slider v-model="specification" />
      </v-col>
    </v-row>
    <v-row class="mt-12">
      <v-col cols="12">
        <h3>StepperProgressBar</h3>
      </v-col>
      <v-col>
        <stepper-progress-bar v-model="step" />
        <br />
        <app-button label="-" class="mr-4" @click="step > 0 && step--" />

        <app-button label="+" @click="step < 5 && step++" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RangeSlider from '@/components/steps/2/RangeSlider';
import StepperProgressBar from '@/components/StepperProgressBar.vue';

export default {
  name: 'BaseComponents',

  components: {
    RangeSlider,
    StepperProgressBar
  },

  data: () => ({
    textInput: '',
    selectItems: [
      { label: 'Filter 1', value: 'x' },
      { label: 'Filter 2', value: 'y' },
      { label: 'Filter 3', value: 'z' }
    ],
    selectedItems: [],
    radioItems: [
      { label: 'Radio 1', value: 'x' },
      { label: 'Radio 2', value: 'y' }
    ],
    selectedRadio: '',
    showDialog: false,
    checked: false,
    step: 1,
    specification: 'mid_range'
  }),

  methods: {
    test() {}
  }
};
</script>
